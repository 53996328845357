import { withLDProvider } from "launchdarkly-react-client-sdk";
import { appWithTranslation } from "next-i18next";
import NextApp, { AppProps } from "next/app";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useMemo } from "react";
import TagManager from "react-gtm-module";

import { GTM_AUTH, GTM_CONTAINER_ID, GTM_PREVIEW } from "@/shared/constants";

import { testIsSupportedBrowser } from "@/utils/browser";
import { getLaunchDarklyProperties } from "@/utils/launchDarkly";
import { applyCacheHeader, createContinuationRequestContext } from "@/utils/requests";

import { trackPageLoad } from "@/analytics/tracking";

import AuthProvider from "@/context/AuthContext";
import { ErrorMessageProvider } from "@/context/ErrorMessageContext";
import { LocaleProvider } from "@/context/LanguageContext";

import { useCookies } from "@/hooks/useCookies";

import { Locale } from "@/types/locale";

import "@/dev/wdyr";

import nextI18NextConfig from "../next-i18next.config";
import "../styles/globals.scss";
import { init as initSentry } from "../utils/sentry";
import AppLoaded from "./app-loaded";

initSentry();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AfterpaySite = ({ Component, pageProps }: AppProps<any>) => {
    const router = useRouter();
    const [storedLocale] = useCookies("NEXT_LOCALE", null);

    // TODO - Standardise the usage of locale by using Page.d.ts type on pages.
    const locale: Locale =
        pageProps?.currentLocale ?? pageProps?.locale ?? storedLocale ?? "global";
    useEffect(() => {
        TagManager.initialize({
            gtmId: GTM_CONTAINER_ID,
            auth: GTM_AUTH,
            preview: GTM_PREVIEW,
        });

        trackPageLoad(router.asPath, locale);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const [ldKey, country, clientSideID] = getLaunchDarklyProperties(locale);
    const ComponentWithAuth = useCallback(
        (props) => (
            <AuthProvider>
                <AppLoaded>
                    <Component {...props} />
                </AppLoaded>
            </AuthProvider>
        ),
        [Component]
    );
    const ComponentWithLaunchDarkly = useMemo(
        () =>
            withLDProvider({
                clientSideID,
                user: {
                    key: ldKey,
                    anonymous: true,
                    custom: { currentLocale: locale },
                    country,
                },
                options: {
                    bootstrap: "localStorage",
                },
            })(ComponentWithAuth),
        [ldKey, locale, country, clientSideID, ComponentWithAuth]
    );

    const isSupportedBrowser = testIsSupportedBrowser();
    if (!isSupportedBrowser) {
        window.location.pathname = "/unsupported.html";
        return null;
    }

    return (
        <LocaleProvider locale={locale}>
            <ErrorMessageProvider errorMessages={{}}>
                <ComponentWithLaunchDarkly {...pageProps} />
            </ErrorMessageProvider>
        </LocaleProvider>
    );
};

AfterpaySite.getInitialProps = async (appContext) => {
    const { req, res } = appContext.ctx;

    createContinuationRequestContext(req, res);
    const appProps = await NextApp.getInitialProps(appContext);

    if (!res.headersSent) {
        applyCacheHeader(res);
    }

    return { ...appProps };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default appWithTranslation(AfterpaySite, nextI18NextConfig as any);
